import style from '../../assets/css/CaseDetails.module.css'
import iconLinkedin from '../../../../assets/images/icon-linkedin.svg'
import iconBehance from '../../../../assets/images/icon-behance.svg'
import iconGithub from '../../../../assets/images/icon-github.svg'
import { OldStudent } from '../../../../types/OldStudent';
import { useState } from 'react';
import { oldStudentsTeamOne } from '../../../../services/OldStudentService';

const CaseDetailsAboutTeamOne = () => {
    const [oldStudents, setOldStudents] = useState<OldStudent[]>(oldStudentsTeamOne());

    return (
        <div className={style.container_content}>
            <h4 id='sector_7' className={style.case_subtitle}>
                7.0 - Sobre a equipe
            </h4>

            <div>
                <div className={style.container_flex} style={{flexWrap:"wrap"}}>
                    {oldStudents.map((oldStudent) => (
                        <div className={style.case_user_card}>
                            <img src={oldStudent.photos[0]} alt="Foto aluno" className={style.case_card_img} />

                            <div className={style.container_flex}>
                                <div className={style.case_card_text_container}>
                                    <span className={style.case_card_text_title}>
                                        {oldStudent.fullName}
                                    </span> <br />

                                    <span className={style.case_card_text}>
                                        {oldStudent.role}
                                    </span>
                                </div>

                                <div className={style.container_flex_card_icons}>
                                    <a href={oldStudent.url.linkedin} target="_blank"
                                        style={{ display: oldStudent.url.linkedin != "" ? "block" : "none" }}>
                                        <img src={iconLinkedin} alt="icon from linkedin" />
                                    </a>

                                    <a href={oldStudent.url.behance} target="_blank"
                                        style={{ display: oldStudent.url.behance != "" ? "block" : "none" }}>
                                        <img src={iconBehance} alt="icon from behance" />
                                    </a>

                                    <a href={oldStudent.url.github} target="_blank"
                                        style={{ display: oldStudent.url.github != "" ? "block" : "none" }}>
                                        <img src={iconGithub} alt="icon from github" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div >
        </div>
    );
}

export default CaseDetailsAboutTeamOne;