import { Student } from "../types/Student";
import { StudentMapper } from "../types/mappers/StudentMapper";
import api from "./Api";

export const getStudentCache = (): Student => {
    var student: Student = {
        fullName: '',
        idCareer: 0,
        email: '',
        cellphone: '',
        hasTechKnowledge: undefined,
        techKnowledge: '',
        techSkills: '',
        idStudyTool: 0,
        urls: '',
        idReferral: 0
    };

    var studentStr = sessionStorage.getItem('currStudent');
    if (studentStr) {
        student = JSON.parse(studentStr);
    }

    return student;
}

export const setStudentCache = (student: Student): void => {
    sessionStorage.setItem('currStudent', JSON.stringify(student));
}

export const studentEmailExists = async (email: String) => {
    return await api.get<Student>(`form/email/${email}`);
}

export const studentCellphoneExists = async (cellphone: String) => {
    return await api.get<Student>(`form/cellphone/${cellphone}`);
}

export const registerStudent = async (student: Student) => {
    return await api.post<Student>('form/register', StudentMapper(student));
}