import { useEffect, useState } from 'react';
import Home from '../Home';
import FirstSession from './components/FirstSession';
import SequencialSession from './components/SequencialSession';
import ConclusionSession from './components/ConclusionSession';
import { Student } from '../../types/Student';
import { getStudentCache, registerStudent, setStudentCache } from '../../services/StudentService';
import NavBar from '../../components/NavBar';
import { useIsMobileOrTablet } from '../../utils/Style';


const ParticipantForms = () => {
    const [page, setPage] = useState<number>(0);
    const [student, setStudent] = useState<Student>(getStudentCache());

    useEffect(() => {
        setStudentCache(student);
    });

    const pageDisplay = () => {
        if (page === 0) {
            return <FirstSession student={student} nextSession={nextSession} />
        } else if (page === 1) {
            return <SequencialSession student={student} prevSession={prevSession} nextSession={nextSession} />
        } else if (page === 2) {
            return <ConclusionSession student={student} />
        } else {
            return <Home />
        }
    };

    const nextSession = (): void => {
        setPage((currPage) => currPage + 1);

        var currPage = page + 1;
        if (currPage >= 2) {
            registerStudent(student)
                .then(res => sessionStorage.removeItem('currStudent'))
                .catch(error => {
                    console.log('Error: ' + error);
                    return;
                });
        }
    }

    const prevSession = (): void => {
        if (page > 0) {
            setPage((currPage) => currPage - 1);
        }
    }

    return (
        <div style={{ backgroundColor: '#000', minHeight: '100vh' }}>
            <NavBar
                isDark
                hideNav={!!useIsMobileOrTablet()}
            />
            {pageDisplay()}
        </div>
    );
}

export default ParticipantForms;
