import style from './css/Button.module.css'
import { ReactComponent as Loader } from '../../assets/images/spinner.svg'

interface ISpinnerButtonProps {
    onSubmit: (e: React.MouseEvent) => void
    text: string
    loading: boolean
}

const SpinnerButton: React.FC<ISpinnerButtonProps> = ({ onSubmit, text, loading }) => {
    return (
        <button className={style.spinner_btn} onClick={onSubmit} disabled={loading}>
            {!loading ? text : <Loader className={style.spinner} />}
        </button>
    );
}

export default SpinnerButton;