import style from '../../../assets/css/CaseDetails.module.css'

const CaseDetailsContentPresentationTwo = () => {
    return (
        <>
            <h4 id='sector_6' className={style.case_subtitle}>
                6.0 - Apresentação
            </h4>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    Nada melhor do que finalizar o seu primeiro projeto com a sua primeira apresentação. Os alunos finalizaram o projeto apresentando toda sua jornada para pessoas de diversas áreas no escritório de nosso grande parceiro, a faculdade de tecnologia FIAP, na Paulista (SP capital).
                </span>
            </div>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    O objetivo da apresentação foi explicar para nossos convidados o porquê do projeto, quais foram os problemas que iriam solucionar e principalmente o que cada um fez durante esses 3 meses. Além de mostrar o que foram capazes de aprender e desenvolver em um curto período, os mesmos criavam o seu primeiro Networking.
                    <br/>Este Networking ocorreu através de nossos convidados. Pessoas de diferentes empresas e diferentes áreas de estudo e senioridade. Eles avaliaram e deram feedbacks sobre a performance da apresentação e posteriormente seguiram para conversas livres e pessoais entre com os alunos.
                    <br/>Essa última etapa do bootcamp é o que chamamos de “Cereja do bolo”, pois em 3 meses foram ensinados diversos itens da tecnologia essenciais para o mercado de trabalho, mas durante esta etapa foi mostrado em aplicação como a comunicação é o fator mais importante da carreira de cada um, como saber apresentar o seu trabalho, suas opiniões e também, como receber e aprender com feedbacks construtivos.
                </span>
            </div>
        </>
    );
};

export default CaseDetailsContentPresentationTwo;
