import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import style from './assets/css/CaseDetails.module.css'
import CaseDetailsOne from './components/CaseDetailsOne';
import CaseDetailsTwo from './components/CaseDetailsTwo';
import NavBar from '../../components/NavBar';
import { useIsMobileOrTablet } from '../../utils/Style';

const CaseDetails: React.FC = () => {
  const [queryParameters] = useSearchParams()

  const [caseChoose, setCaseChoose] = useState<String | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setCaseChoose(queryParameters.get('case'));
    if (caseChoose) {
      navigate('/cases');
    }
  }, []);

  const currentCaseDetail = () => {
    if (caseChoose === '1') {
      return <CaseDetailsOne />
    } else if (caseChoose === '2') {
      return <CaseDetailsTwo />
    } else {
      navigate('/cases');
    }
  };

  return (
    <div className={style.case_detais_body}>
      <NavBar
        hideLogo={true}
        background={!!useIsMobileOrTablet() ? '' : '#fff'}
        shadow={!!useIsMobileOrTablet() ? 'none' : '0px 4px 15px rgba(0, 0, 0, 0.06)'}
      />
      {currentCaseDetail()}
    </div>
  );
};

export default CaseDetails;
