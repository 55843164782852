import style from '../assets/css/CaseDetails.module.css'
import Footer from '../../../components/Footer';
import CaseDetailsIntroductionOne from './caseDetailsOne/CaseDetailsIntroductionOne';
import CaseDetailsIndex from './CaseDetailsIndex';
import CaseDetailsContentOne from './caseDetailsOne/CaseDetailsContentOne';
import CaseDetailsAboutTeamOne from './caseDetailsOne/CaseDetailsAboutTeamOne';

const CaseDetailsOne: React.FC = () => {
  return (
    <div className={style.case_detais_body}>
      <CaseDetailsIntroductionOne />
      <CaseDetailsIndex />
      <CaseDetailsContentOne />
      <CaseDetailsAboutTeamOne />
      <Footer />
    </div>
  );
};

export default CaseDetailsOne;
