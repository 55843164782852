import { getImageCaseOne } from '../../../../../services/ImageService';
import { getOldStudentByName } from '../../../../../services/OldStudentService';
import style from '../../../assets/css/CaseDetails.module.css'

const CaseDetailsContentBackendOne = () => {
    const image15: string = 'case-details-15.svg';

    const mateusToni = getOldStudentByName(1, 'Mateus Toni');
    const mateusToniLink = <a className={style.link_in_text} href={mateusToni?.url.linkedin} target="_blank">{mateusToni?.fullName}</a>;

    return (
        <>
            <h4 id='sector_3' className={style.case_subtitle}>
                3.0 - Back-end
            </h4>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    O time de back-end foi representado pelo {mateusToniLink}, que foi responsável por toda a aplicação Workstation, disponibilizando um ambiente para as integrações.
                </span>
            </div>

            <h5 className={style.case_text_bold}>
                O que foi disponibilizado:
            </h5>

            <div className={style.case_text_container}>
                <ul className={style.case_text}>
                    <li>Diagramas de caso de uso;</li>
                    <li>Diagramas de caso de teste;</li>
                    <li>Fluxograma da Jornada do Usuário;</li>
                    <li>Diagrama do banco de dados.</li>
                </ul>
            </div>

            <h5 className={style.case_text_bold}>
                O que era esperado:
            </h5>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    Aplicação Restfull com endpoints protegidos por JWT e conexão com banco de dados, e a utilização de boas praticas de desenvolvimento. De início foi criado o repositório back-end e a estrutura da aplicação flask, juntamente com o banco de dados seguindo o seguinte diagrama:
                </span>
            </div>

            <img src={getImageCaseOne(image15)} alt="Imagem 15" className={style.case_img} />

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    Com a criação de CRUDs necessários para o funcionamento essencial da aplicação (MVP) foi a hora de criar os endpoints e conectar cada um deles com uma chamada ao banco de dados. Realizado todo o fluxo e com testes feitos, a hora de conectar com o front-end finalmente chegou, com todas as requisições vindo agora de uma outra aplicação por meio das comunicações HTTP e protegidas por JWT. Foi realizado algumas correções que não foram previstas na lógica inicial, porém com a aplicação pronta tinha chegado a hora de preparar a apresentação final (pitch), contando em detalhes como foi a experiência do bootcamp e o que foi aprendido no processo.
                </span>
            </div>

            <h5 className={style.case_text_bold}>
                Tecnologias utilizadas
            </h5>

            <div className={style.case_text_container}>
                <ul className={style.case_text}>
                    <li>Flask;</li>
                    <li>MySQL;</li>
                    <li>JWT;</li>
                    <li>Docker;</li>
                    <li>Git e GitHub;</li>
                    <li>Comunicação Restfull.</li>
                </ul>
            </div>
        </>
    );
};

export default CaseDetailsContentBackendOne;
