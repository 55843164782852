import { getImageCaseOne } from '../../../../../services/ImageService';
import style from '../../../assets/css/CaseDetails.module.css'

const CaseDetailsContentDesignOne: React.FC = () => {
    const image9: string = 'case-details-9.svg';
    const image10: string = 'case-details-10.svg';
    const image11: string = 'case-details-11.svg';
    const image12: string = 'case-details-12.svg';
    const image13: string = 'case-details-13.svg';

    return (
        <>
            <h4 id='sector_2' className={style.case_subtitle}>
                2.0 - UX / UI
            </h4>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    Após o brainstorm iniciou o processo de discovery para a squad entender o porquê do projeto e seus respectivos problemas que deveriam ser solucionados. Foram realizados diversos levantamentos de dados analisados por pesquisadores sobre a pandemia e como os trabalhadores se adaptaram a esse "Novo normal".
                </span>
            </div>

            <img src={getImageCaseOne(image9)} alt="Imagem 9" className={style.case_img} />

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    Com o objetivo mais claro em mãos, o squad começou a desenhar os seus primeiros wireframes para validar ideias e alinhar com o time de back-end as possibilidades do que fazer. Considerando a tecnologia utilizada e o tempo disponibilizado.
                </span>
            </div>

            <div className={style.container_flex}>
                <div className={style.container_image}>
                    <img src={getImageCaseOne(image10)} alt="Imagem 10" className={style.case_img} />
                </div>

                <div className={style.container_image}>
                    <img src={getImageCaseOne(image11)} alt="Imagem 11" className={style.case_img} />
                </div>
            </div>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    Utilizando a base desenhada e alinhada através dos wireframes, o designer migrou todas as ideias levantadas para a interface final.
                </span>
            </div>

            <div className={style.container_flex}>
                <div className={style.container_image}>
                    <img src={getImageCaseOne(image12)} alt="Imagem 12" className={style.case_img} />
                </div>

                <div className={style.container_image}>
                    <img src={getImageCaseOne(image13)} alt="Imagem 13" className={style.case_img} />
                </div>
            </div>
        </>
    );
};

export default CaseDetailsContentDesignOne;
