import { useEffect, useState } from 'react';
import { Case } from '../../types/Case';
import style from './components/css/Cases.module.css';
import ColumnGray from '../../assets/images/column-gray.svg';
import ColumnPurple from '../../assets/images/column-purple.svg';
import GridGray from '../../assets/images/grid-gray.svg';
import GridPurple from '../../assets/images/grid-purple.svg';
import useWindowSize from '../../utils/useWindowSize';
import CaseItem from './components/CaseItem';

import WorkstationImg from '../../assets/images/cases_images/workstation.svg';
import WorkstationLogo from '../../assets/images/cases_images/workstation_logo.svg';
import WorkstationMobile from '../../assets/images/cases_images/workstation_mobile.svg';
import WorkstationCard from '../../assets/images/cases_images/workstation_card.svg';

import NoviImg from '../../assets/images/cases_images/novi.svg';
import NoviLogo from '../../assets/images/cases_images/novi_logo.svg';
import NoviMobile from '../../assets/images/cases_images/novi_mobile.svg';
import NoviCard from '../../assets/images/cases_images/novi_card.svg';
import NavBar from '../../components/NavBar';
import { useIsMobileOrTablet } from '../../utils/Style';

const Cases: React.FC = () => {
  const { width } = useWindowSize();
  const [grid, setGrid] = useState(false);
  const [cases, setCases] = useState<Case[]>([
    {
      id: '1',
      title: 'Workstation',
      subtitle: 'Web Application',
      description: 'Case desenvolvido em nosso primeiro bootcamp. Seu objetivo é atender a demanda de alugueis de espaços para trabalhos individuais que necessitam de uma sala equipada com o que um escritório teria. Seu diferencial é a locomoção, ou seja, uma “box” que pode ser implementada em uma estação de trem, shoppings, parques etc.',
      image: WorkstationImg,
      image_card: WorkstationCard,
      logo: WorkstationLogo,
      mobile_img: WorkstationMobile,
      themeColor: '#FF0A55'
    },
    {
      id: '2',
      title: 'Novi',
      subtitle: 'Web Application',
      description: 'Case desenvolvido em nossa segunda turma do bootcamp. Sua proposta foi a criação de uma agência de viagens personalizada. Seu diferencial é de descobrir a viagem perfeita de alguém através de um simples questionário.',
      image: NoviImg,
      image_card: NoviCard,
      logo: NoviLogo,
      mobile_img: NoviMobile,
      themeColor: '#3BB29D'
    }
  ]);

  useEffect(() => {
    if (width && width <= 800) {
      setGrid(false);
    }
  }, [width]);

  return (
    <div className={`${grid ? style.case_full_background : style.case_half_background}`}>
      <NavBar
        isDark={true}
        background={useIsMobileOrTablet() ? '' : '#181818'}
        hideLogo={!!useIsMobileOrTablet()}
      />
      <div className={`${grid ? style.grid_container : style.full_container}`}>
        <div className={style.choose_grid_background}>
          <div className={style.choose_grid_container}>
            <div className={style.choose_grid}>
              <button
                className={`${style.grid_button} ${!grid ? style.active : ''}`}
                onClick={() => setGrid(false)}
              >
                <img src={grid ? ColumnGray : ColumnPurple} alt="grid" />
              </button>

              <button
                className={`${style.grid_button} ${grid ? style.active : ''}`}
                onClick={() => setGrid(true)}
              >
                <img src={grid ? GridPurple : GridGray} alt="grid" />
              </button>
            </div>
          </div>
        </div>

        {
          cases.map((caseItem, item) => (
            <CaseItem
              caseItem={caseItem}
              next_id={item + 1 < cases.length ? cases[item + 1].id : undefined}
              grid={grid}
            />
          ))
        }
      </div>
    </div>
  )
}

export default Cases;