import style from './css/Cases.module.css';
import PurpleArrow from '../../../assets/images/purple-arrow-cases.svg';
import useWindowSize from '../../../utils/useWindowSize';
import { Link } from 'react-router-dom';
import { Case } from '../../../types/Case';

interface IProps {
  caseItem: Case;
  next_id?: string;
  grid: boolean;
}

const CaseItem: React.FC<IProps> = ({caseItem, next_id, grid}) => {
  const { width } = useWindowSize();
  const link = `/cases/details?case=${caseItem.id}`

  return (
    <div className={grid ? style.grid : style.full} style={{ background: width && width <= 800 && !grid ? caseItem.themeColor : '' }}>
      <div className={style.case} id={caseItem.id}>
        <div className={style.case_image}>
          <Link to={link} target="_blank" rel="noopener noreferrer">
            <img src={grid ? caseItem.image_card : caseItem.image} alt={caseItem.title} />
          </Link>
        </div>
        <div className={style.content}>
          {!grid && (
            <div className={style.logo_image}>
              <img src={caseItem.logo} alt="logo" />
            </div>
          )}
          <div className={style.title}>
            <Link to={link} target="_blank" rel="noopener noreferrer">{caseItem.title}</Link>
            <span>{caseItem.subtitle}</span>
          </div>
          <div className={style.description}>
            <p>{caseItem.description}</p>
            <Link to={link} className={style.see_more} target="_blank" rel="noopener noreferrer">Ver mais</Link>
            {next_id && <a className={style.arrow} href={`#${next_id}`}><img src={PurpleArrow} alt="Próximo card" /></a>}
          </div>
        </div>
        {!grid && (
          <div className={style.mobile_image}>
            <img src={caseItem.mobile_img} alt={caseItem.title} />
          </div>
        )}
      </div>
    </div>
  )
}

export default CaseItem