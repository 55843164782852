import { CertificateType } from '../../../types/Certificate';
import style from './css/Certificate.module.css';

type CertificateProps = {
  certificateInfos: CertificateType['info_certificate']
}

const Certificate: React.FC<CertificateProps> = ({
  certificateInfos
}) => {
  const {
    bootcamp_name, date_certificate, full_name, url, preview_img, duration
  } = certificateInfos;

  const certificate_year = new Date(date_certificate).getFullYear();

  return (
    <div className={style.container}>
      <div className={style.certificate_preview} style={{ backgroundImage: `url(${preview_img})` }}></div>
      <div className={style.certificate_infos}>
        <div>
          <h2 className={style.project_name}>{bootcamp_name}</h2>
          <p className={style.project_year}>{certificate_year}</p>
          <p className={style.project_description}>
            {`${full_name} Terminou com êxito sua jornada no bootcamp Start Future (${duration} meses)`}
          </p>
        </div>
        <a className={style.download_button} href={url} download>Download</a>
      </div>
    </div>
  )
}

export default Certificate;
