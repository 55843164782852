import { getImageCaseOne } from '../../../../../services/ImageService';
import { getOldStudentByName } from '../../../../../services/OldStudentService';
import style from '../../../assets/css/CaseDetails.module.css'

const CaseDetailsContentFrontendOne = () => {
    const image16: string = 'case-details-16.svg';

    const gustavoVieira = getOldStudentByName(1, 'Gustavo Vieira');
    const gustavoVieiraLink = <a className={style.link_in_text} href={gustavoVieira?.url.linkedin} target="_blank">{gustavoVieira?.fullName}</a>;

    return (
        <>
            <h4 id='sector_4' className={style.case_subtitle}>
                4.0 - Front-end
            </h4>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    O time de front-end foi representado pelo {gustavoVieiraLink}, responsável por toda a parte visual do sistema Workstation. Em mãos tinha as telas e protótipos navegáveis das telas necessárias, alinhadas internamente com os Staff's e toda a equipe participante.
                </span>
            </div>

            <h5 className={style.case_text_bold}>
                O que era esperado:
            </h5>

            <div className={style.case_text_container}>
                <ul className={style.case_text}>
                    <li>Criação de telas com o layout definido pelo designer;</li>
                    <li>Código limpo e bem estruturado;</li>
                    <li>Responsividade (visualização web e mobile);</li>
                    <li>Validação e funcionalidade de elementos interativos.</li>
                </ul>
            </div>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    Assim como a jornada do back-end, inicialmente foi feita a criação do ambiente de desenvolvimento (repositórios e estrutura do projeto). Depois se seguiu para o planejamento da estrutura das primeiras telas, montando como serão escritas os níveis de hierarquia do HTML e CSS.
                </span>
            </div>

            <img src={getImageCaseOne(image16)} alt="Imagem 16" className={style.case_img} />

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    O desenvolvimento seguiu para a criação e montagem das telas e seus respectivos fluxos. Focando na visualização Web. Após construção do Web foi convertido para uma visualização Mobile, ou seja, adicionado a responsividade das telas para sua visualização em qualquer tela. Nas telas de Login, Criação de Usuários e Recuperação de senha, foi adicionado as respectivas validações dos dados dos campos, por exemplo CPF, E-mail etc. Foi finalizado com a publicação do site para visualização no sistema Netlify, correção de bugs, revisão de textos e conexão Front-end e Back-end. Prática e mais prática para o pitch, e a apresentação final do projeto junto com o que foi aprendido na trajetória.
                </span>
            </div>

            <h5 className={style.case_text_bold}>
                Tecnologias utilizadas:
            </h5>

            <div className={style.case_text_container}>
                <ul className={style.case_text}>
                    <li>HTML;</li>
                    <li>CSS;</li>
                    <li>JavaScript;</li>
                    <li>Bootstrap;</li>
                    <li>Git e Github;</li>
                    <li>Deploy em nuvem Netlify.</li>
                </ul>
            </div>
        </>
    );
};

export default CaseDetailsContentFrontendOne;
