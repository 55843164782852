import { getImageCaseTwo } from '../../../../services/ImageService';
import style from '../../assets/css/CaseDetails.module.css'

const CaseDetailsIntroductionTwo: React.FC = () => {
    const image1: string = 'case-details-1.svg';
    const image2: string = 'case-details-2.svg';
    const image3: string = 'case-details-3.svg';

    return (
        <div className={style.container}>
            <div>
                <span className={style.case_previous_page_text}>
                    Cases / <strong>Noví</strong>
                </span>
            </div>

            <h1 className={style.case_title}>
                Noví
            </h1>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    No dia a dia vivemos diversas decisões dificeis, por exemplo escolher um filme em um streaming, uma playlist de música, um vídeo para passar o tempo etc. Por isso muitas redes começaram a estudar nossos gostos pessoais e oferecer oportunidades de nos divertir de forma mais personalizada. O que nos leva ao projeto da vez O case proposto para a turma 2 teve como objetivo descobrir a viagem perfeita de uma pessoa através de uma entrevista para entendermos seus gostos. Através de nossa empresa Noví* fazemos uma série de perguntas sobre preferencias da pessoa, rodamos por uma IA e encontramos sua viagem dos sonhos em poucos segundos! Durante as 12 semanas seguintes estivemos juntos nessa caminhada em um squad de 6 pessoas passando por áreas de back-end, front-end, design e gestão.
                </span>
                <br /><br />
                <span className={style.case_text}>
                    Além de diversos momentos marcantes como mentorias personalizadas, aulas de aprofundamentos, encontros presenciais e culminando em nossa grande apresentação final.
                </span>
                <br /><br />
                <span className={style.case_text}>
                    <strong>*</strong>Empresa fictícia criada para desenvolvimento do projeto didático.
                </span>
                <br />
            </div>

            <img src={getImageCaseTwo(image1)} alt="Imagem 1" className={style.case_img} />
            <img src={getImageCaseTwo(image2)} alt="Imagem 2" className={style.case_img} />
            <img src={getImageCaseTwo(image3)} alt="Imagem 3" className={style.case_img} />

            <h4 className={style.case_subtitle_initial}>
                Aulas de aprofundamento
            </h4>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    Durante as primeiras 6 semanas, tivemos 2 aulas por semana sobre conhecimentos de diversas áreas para o aprofundamento de conhecimento dos alunos.
                </span>
                <br />
                <span className={style.case_text}>
                    Conteúdos abordados com os alunos:
                </span>
                <ul>
                    <li>Brainstorm e Briefing</li>
                    <li>Scrum</li>
                    <li>Kanban e Moscow</li>
                    <li>UX/UI</li>
                    <li>Introdutória a conteúdos técnicos (lógica de programação e aprofundamentos)</li>
                    <li>GIT</li>
                    <li>Regex</li>
                    <li>Docker</li>
                    <li>React</li>
                    <li>AWS</li>
                </ul>
            </div>

            <hr />
        </div>
    );
}

export default CaseDetailsIntroductionTwo;