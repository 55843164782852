import { CertificateType } from "../types/Certificate";
import { OldStudent } from "../types/OldStudent";
import { getImageCaseOne, getImageCaseTwo } from "./ImageService";

export function oldStudentsTeamOne(): OldStudent[] {
    return [
        {
            id: 1,
            fullName: "Gustavo Fernandes",
            photos: [getImageCaseOne("photo-gustavo-fernandes.png")],
            role: "Designer UX/UI",
            url: {
                linkedin: 'https://www.linkedin.com/in/gustavofernandesp/',
                behance: "https://www.behance.net/gustavofernandessp22",
                github: "",
            },
            certificate: {
                code: "VXKMW-J0LYB-D68EF-DD93V",
                projectName: "Workstation",
                projectYear: "2021-03-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-1/VXKMW-J0LYB-D68EF-DD93V.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-1/VXKMW-J0LYB-D68EF-DD93V.pdf",
            }
        },
        {
            id: 2,
            fullName: "Mateus Toni",
            photos: [getImageCaseOne("photo-mateus-toni.png")],
            role: "Desenvolvedor Back-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/mateus-toni-941853217/',
                github: "https://github.com/Mateus-Toni",
                behance: ""
            },
            certificate: {
                code: "T37MQ-G4BCF-ZJFIK-5HXO9",
                projectName: "Workstation",
                projectYear: "2021-03-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-1/T37MQ-G4BCF-ZJFIK-5HXO9.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-1/T37MQ-G4BCF-ZJFIK-5HXO9.pdf",
            }
        },
        {
            id: 3,
            fullName: "Gustavo Vieira",
            photos: [getImageCaseOne("photo-gustavo-vieira.png")],
            role: "Desenvolvedor Front-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/gustavo-vieira-dev/',
                github: "https://github.com/szvieira",
                behance: ""
            },
            certificate: {
                code: "8HBB1-4447O-F9W4G-WMUQX",
                projectName: "Workstation",
                projectYear: "2021-03-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-1/8HBB1-4447O-F9W4G-WMUQX.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-1/8HBB1-4447O-F9W4G-WMUQX.pdf",
            }
        },
        {
            id: 4,
            fullName: "Selena Saraiva",
            photos: [getImageCaseOne("photo-selena-saraiva.png")],
            role: "Gerente de Projetos",
            url: {
                linkedin: 'https://www.linkedin.com/in/selena-saraiva-4248611ba/',
                github: "",
                behance: ""
            },
            certificate: {
                code: "2AR16-W3NI7-6CW69-OQ57X",
                projectName: "Workstation",
                projectYear: "2021-03-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-1/2AR16-W3NI7-6CW69-OQ57X.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-1/2AR16-W3NI7-6CW69-OQ57X.pdf",
            }
        }
    ];
}

export function oldStudentsTeamTwo(): OldStudent[] {
    return [
        {
            id: 1,
            fullName: "Giovana de Almeida",
            photos: [
                getImageCaseTwo("photo-giovana-almeida.svg"),
                getImageCaseTwo("photo-giovana-almeida-certificate.svg"),
            ],
            role: "Back-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/giovana-almeida11/',
                github: 'https://github.com/giovana-git/',
                behance: ''
            },
            certificate: {
                code: "6UNX-BT5L-SGBL-379K",
                projectName: "Noví",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-2/6UNX-BT5L-SGBL-379K.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-2/6UNX-BT5L-SGBL-379K.pdf",
            }
        },
        {
            id: 2,
            fullName: "Gabriel Torres",
            photos: [
                getImageCaseTwo("photo-gabriel-torres.svg"),
                getImageCaseTwo("photo-gabriel-torres-certificate.svg")
            ],
            role: "Back-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/gabriel-torres-cecconi-1a395522b/',
                github: 'https://github.com/GabrielCecconi25/',
                behance: ''
            },
            certificate: {
                code: "BXJ8-EPSU-458Q-PSRO",
                projectName: "Noví",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-2/BXJ8-EPSU-458Q-PSRO.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-2/BXJ8-EPSU-458Q-PSRO.pdf",
            }
        },
        {
            id: 3,
            fullName: "Henrique Jordão",
            photos: [
                getImageCaseTwo("photo-henrique-jordao.svg"),
                getImageCaseTwo("photo-henrique-jordao-certificate.svg")
            ],
            role: "Front-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/henrique-jordão-baier-083a9523b/',
                github: 'https://github.com/henriquebayer1/',
                behance: ''
            },
            certificate: {
                code: "VU8V-90YY-AB6W-O7ZF",
                projectName: "Noví",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-2/VU8V-90YY-AB6W-O7ZF.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-2/VU8V-90YY-AB6W-O7ZF.pdf",
            }
        },
        {
            id: 4,
            fullName: "Luiz Henrique",
            photos: [
                getImageCaseTwo("photo-luiz-henrique.svg"),
                getImageCaseTwo("photo-luiz-henrique-certificate.svg")
            ],
            role: "Back-end",
            url: {
                linkedin: 'http://linkedin.com/in/luiz-henrique-mota-guimar%C3%A3es-ab7469243/',
                github: 'https://github.com/luizhmg/',
                behance: ''
            },
            certificate: {
                code: "PC3G-UQ0N-YQ0N-91C3",
                projectName: "Noví",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-2/PC3G-UQ0N-YQ0N-91C3.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-2/PC3G-UQ0N-YQ0N-91C3.pdf",
            }
        },
        {
            id: 5,
            fullName: "Thiago Yuske",
            photos: [
                getImageCaseTwo("photo-thiago-yuske.svg"),
                getImageCaseTwo("photo-thiago-yuske-certificate.svg")
            ],
            role: "Front-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/thiagoomori/',
                github: 'https://github.com/ThiagoYuske/',
                behance: ''
            },
            certificate: {
                code: "4BSA-ZJX3-49T9-0Y9T",
                projectName: "Noví",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-2/4BSA-ZJX3-49T9-0Y9T.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-2/4BSA-ZJX3-49T9-0Y9T.pdf",
            }
        },
        {
            id: 6,
            fullName: "Alessandro Janicki",
            photos: [
                getImageCaseTwo("photo-alessandro-janicki.svg"),
                getImageCaseTwo("photo-alessandro-janicki-certificate.svg")
            ],
            role: "Front-end / Back-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/alejanicki/',
                github: 'https://github.com/alejanicki/',
                behance: ''
            },
            certificate: {
                code: "2R1Z-11WR-7G1U-K7K5",
                projectName: "Noví",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-2/2R1Z-11WR-7G1U-K7K5.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-2/2R1Z-11WR-7G1U-K7K5.pdf",
            }
        },
    ];
}

export function oldStudentsTeamThree(): OldStudent[] {
    return [
        {
            id: 1,
            fullName: "Beatriz Andrade",
            photos: [],
            role: "Designer UX/UI",
            url: {
                linkedin: 'https://www.linkedin.com/in/beandy-cyber/',
                github: 'https://github.com/beandy-cyber',
                behance: ''
            },
            certificate: {
                code: "4BB5-31J7-IZ46-GF8E",
                projectName: "Onboarding /Me",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-3/4BB5-31J7-IZ46-GF8E.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-3/4BB5-31J7-IZ46-GF8E.pdf",
            }
        },
        {
            id: 2,
            fullName: "Bruno Bergamasco",
            photos: [],
            role: "Front-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/bruno-bergamasco-698880266/',
                github: 'https://github.com/bergamascobruno',
                behance: ''
            },
            certificate: {
                code: "O98X-PF0C-GZ8B-7QXF",
                projectName: "Onboarding /Me",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-3/O98X-PF0C-GZ8B-7QXF.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-3/O98X-PF0C-GZ8B-7QXF.pdf",
            }
        },
        {
            id: 3,
            fullName: "Gabriel Vinicius",
            photos: [],
            role: "Front-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/GabrielHalls/',
                github: 'https://github.com/GabrielHalls',
                behance: ''
            },
            certificate: {
                code: "KHPU-9UK8-11DD-CJ0O",
                projectName: "Onboarding /Me",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-3/KHPU-9UK8-11DD-CJ0O.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-3/KHPU-9UK8-11DD-CJ0O.pdf",
            }
        },
        {
            id: 4,
            fullName: "Igor Nunes",
            photos: [],
            role: "Front-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/igornunesteixeira/',
                github: 'https://github.com/IgornTeii',
                behance: ''
            },
            certificate: {
                code: "QHE2-CUP1-5E8Q-8UW9",
                projectName: "Onboarding /Me",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-3/QHE2-CUP1-5E8Q-8UW9.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-3/QHE2-CUP1-5E8Q-8UW9.pdf",
            }
        },
        {
            id: 5,
            fullName: "Isabella Ferreira",
            photos: [],
            role: "Front-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/isabella-ferreira/',
                github: 'https://github.com/IsabellajFerreira',
                behance: ''
            },
            certificate: {
                code: "HN4B-AAGU-J36D-87AK",
                projectName: "Onboarding /Me",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-3/HN4B-AAGU-J36D-87AK.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-3/HN4B-AAGU-J36D-87AK.pdf",
            }
        },
        {
            id: 6,
            fullName: "Larissa Fazani",
            photos: [],
            role: "Scrum Master",
            url: {
                linkedin: 'https://www.linkedin.com/in/larissa-fazani-b76b3523a/',
                github: 'https://github.com/LariFazani',
                behance: ''
            },
            certificate: {
                code: "R5DG-OP4P-SKKB-Y9VC",
                projectName: "Onboarding /Me",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-3/R5DG-OP4P-SKKB-Y9VC.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-3/R5DG-OP4P-SKKB-Y9VC.pdf",
            }
        },
        {
            id: 7,
            fullName: "Levi Gabriel",
            photos: [],
            role: "Back-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/levigabrielaraujo/',
                github: 'https://github.com/Furfull',
                behance: ''
            },
            certificate: {
                code: "17DC-SUDU-YCUP-LBE6",
                projectName: "Onboarding /Me",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-3/17DC-SUDU-YCUP-LBE6.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-3/17DC-SUDU-YCUP-LBE6.pdf",
            }
        },
        {
            id: 8,
            fullName: "Marco Antonio",
            photos: [],
            role: "Back-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/marco-antonio-pereira-esteves-005185113/',
                github: 'https://github.com/comar80',
                behance: ''
            },
            certificate: {
                code: "5RGC-UTJ2-4Z9P-RL51",
                projectName: "Onboarding /Me",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-3/5RGC-UTJ2-4Z9P-RL51.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-3/5RGC-UTJ2-4Z9P-RL51.pdf",
            }
        },
        {
            id: 9,
            fullName: "Nicholas dos Reis",
            photos: [],
            role: "Back-end",
            url: {
                linkedin: '',
                github: 'https://github.com/NicholasDRR',
                behance: ''
            },
            certificate: {
                code: "PZDU-KZUB-WO1L-UFCS",
                projectName: "Onboarding /Me",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-3/PZDU-KZUB-WO1L-UFCS.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-3/PZDU-KZUB-WO1L-UFCS.pdf",
            }
        },
        {
            id: 10,
            fullName: "Tereza Sophia",
            photos: [],
            role: "Back-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/tereza-sophia-katuyama/',
                github: 'https://github.com/TerezaSophia',
                behance: ''
            },
            certificate: {
                code: "GYQ8-U7MJ-923G-SB80",
                projectName: "Onboarding /Me",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-3/GYQ8-U7MJ-923G-SB80.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-3/GYQ8-U7MJ-923G-SB80.pdf",
            }
        },
        {
            id: 11,
            fullName: "Victor Sousa",
            photos: [],
            role: "Back-end",
            url: {
                linkedin: '',
                github: 'https://github.com/victorsousalima',
                behance: ''
            },
            certificate: {
                code: "LLB7-16B1-7UGV-M9DT",
                projectName: "Onboarding /Me",
                projectYear: "2023-06-01",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-3/LLB7-16B1-7UGV-M9DT.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-3/LLB7-16B1-7UGV-M9DT.pdf",
            }
        }
    ]
}

export function oldStudentsTeamFour(): OldStudent[] {
    return [
        {
            id: 1,
            fullName: "Cibele Jaen de Souza Castro",
            photos: [],
            role: "Scrum Master and Designer UX/UI",
            url: {
                linkedin: 'https://www.linkedin.com/in/cibele-jaen/',
                github: '',
                behance: ''
            },
            certificate: {
                code: "8SW0-0WTX-N8AK-3T1J",
                projectName: "Myclosety",
                projectYear: "2024-03-30",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-4/8SW0-0WTX-N8AK-3T1J.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-4/8SW0-0WTX-N8AK-3T1J.pdf",
            }
        },
        {
            id: 2,
            fullName: "Everton Danilo Lustosa Ribeiro",
            photos: [],
            role: "Desenvolvedor Back-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/everton-danilo-lustosa-ribeiro-a8a419107/',
                github: 'https://github.com/EvertonDev93',
                behance: ''
            },
            certificate: {
                code: "UNH8-7FXT-44GN-KO3M",
                projectName: "Myclosety",
                projectYear: "2024-03-30",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-4/UNH8-7FXT-44GN-KO3M.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-4/UNH8-7FXT-44GN-KO3M.pdf",
            }
        },
        {
            id: 3,
            fullName: "Gabriel Teixeira Machado",
            photos: [],
            role: "Desenvolvedor Front-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/gabrielmachadodev/',
                github: 'https://github.com/MachadONLY',
                behance: ''
            },
            certificate: {
                code: "5FCL-CFXP-95UI-9BZA",
                projectName: "Myclosety",
                projectYear: "2024-03-30",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-4/5FCL-CFXP-95UI-9BZA.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-4/5FCL-CFXP-95UI-9BZA.pdf",
            }
        },
        {
            id: 4,
            fullName: "João Lucas Yudi Redi Handa",
            photos: [],
            role: "Desenvolvedor Back-end",
            url: {
                linkedin: 'https://linkedin.com/in/jo%C3%A3o-yudi/',
                github: 'https://github.com/JoaoLucasYudi',
                behance: ''
            },
            certificate: {
                code: "IB43-0Y2H-MWR3-KJW6",
                projectName: "Myclosety",
                projectYear: "2024-03-30",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-4/IB43-0Y2H-MWR3-KJW6.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-4/IB43-0Y2H-MWR3-KJW6.pdf",
            }
        },
        {
            id: 5,
            fullName: "Laura Claro Mathias",
            photos: [],
            role: "Desenvolvedor Back-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/laura-claro-mathias-580965222/',
                github: 'https://github.com/DevCodeMathias',
                behance: ''
            },
            certificate: {
                code: "7K62-161W-72NN-DSNS",
                projectName: "Myclosety",
                projectYear: "2024-03-30",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-4/7K62-161W-72NN-DSNS.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-4/7K62-161W-72NN-DSNS.pdf",
            }
        },
        {
            id: 6,
            fullName: "Leonardo Valencia Hirano",
            photos: [],
            role: "Scrum Master",
            url: {
                linkedin: 'https://www.linkedin.com/in/leovh/',
                github: '',
                behance: ''
            },
            certificate: {
                code: "HRJV-IH3E-YH3N-6VLE",
                projectName: "Myclosety",
                projectYear: "2024-03-30",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-4/HRJV-IH3E-YH3N-6VLE.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-4/HRJV-IH3E-YH3N-6VLE.pdf",
            }
        },
        {
            id: 7,
            fullName: "Lucas Alves Vieira",
            photos: [],
            role: "Designer UX/UI",
            url: {
                linkedin: 'https://www.linkedin.com/in/lucas-alves-vieira-461542300/',
                github: '',
                behance: ''
            },
            certificate: {
                code: "A0IX-PKH9-CVG7-T78V",
                projectName: "Myclosety",
                projectYear: "2024-03-30",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-4/A0IX-PKH9-CVG7-T78V.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-4/A0IX-PKH9-CVG7-T78V.pdf",
            }
        },
        {
            id: 8,
            fullName: "Matheus Augusto Leite",
            photos: [],
            role: "Desenvolvedor Full-Stack",
            url: {
                linkedin: 'http://linkedin.com/in/augustomath/',
                github: 'https://github.com/mthaugusto/',
                behance: ''
            },
            certificate: {
                code: "AUKL-KT2F-LBKV-PY1G",
                projectName: "Myclosety",
                projectYear: "2024-03-30",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-4/AUKL-KT2F-LBKV-PY1G.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-4/AUKL-KT2F-LBKV-PY1G.pdf",
            }
        },
        {
            id: 9,
            fullName: "Mauricio Coutinho Mandu Gaia",
            photos: [],
            role: "Designer UX/UI",
            url: {
                linkedin: 'https://www.linkedin.com/in/mauricio-mandu-gaia/',
                github: '',
                behance: ''
            },
            certificate: {
                code: "9GYJ-6N29-PFLC-91RX",
                projectName: "Myclosety",
                projectYear: "2024-03-30",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-4/9GYJ-6N29-PFLC-91RX.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-4/9GYJ-6N29-PFLC-91RX.pdf",
            }
        },
        {
            id: 10,
            fullName: "Miguel Venditti",
            photos: [],
            role: "Desenvolvedor Back-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/miguel-venditti-43837521a/',
                github: 'https://github.com/MiguelVenditti',
                behance: ''
            },
            certificate: {
                code: "3FZ7-A9UG-WVSG-77C7",
                projectName: "Myclosety",
                projectYear: "2024-03-30",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-4/3FZ7-A9UG-WVSG-77C7.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-4/3FZ7-A9UG-WVSG-77C7.pdf",
            }
        },
        {
            id: 11,
            fullName: "Thiago Rocha",
            photos: [],
            role: "Desenvolvedor Back-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/thiago-rocha-08080b1ba/',
                github: 'https://github.com/thygss',
                behance: ''
            },
            certificate: {
                code: "SV61-82TZ-1EYR-1BLQ",
                projectName: "Myclosety",
                projectYear: "2024-03-30",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-4/SV61-82TZ-1EYR-1BLQ.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-4/SV61-82TZ-1EYR-1BLQ.pdf",
            }
        },
        {
            id: 12,
            fullName: "Vivian Karine Monteiro Almeida",
            photos: [],
            role: "Designer UX/UI",
            url: {
                linkedin: 'https://www.linkedin.com/in/vivian-monteiro-877a25204/',
                github: '',
                behance: ''
            },
            certificate: {
                code: "3IY7-4RIS-G84U-JDY0",
                projectName: "Myclosety",
                projectYear: "2024-03-30",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-4/3IY7-4RIS-G84U-JDY0.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-4/3IY7-4RIS-G84U-JDY0.pdf",
            }
        },
        {
            id: 13,
            fullName: "Wanessa Aires Mendes",
            photos: [],
            role: "Designer UX/UI",
            url: {
                linkedin: 'https://www.linkedin.com/in/wanessa-mendes-60432a1a2/',
                github: '',
                behance: ''
            },
            certificate: {
                code: "S6A2-6O9M-3RRH-IDKU",
                projectName: "Myclosety",
                projectYear: "2024-03-30",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-4/S6A2-6O9M-3RRH-IDKU.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-4/S6A2-6O9M-3RRH-IDKU.pdf",
            }
        },
        {
            id: 14,
            fullName: "Welton Pereira de Souza",
            photos: [],
            role: "Desenvolvedor Back-end",
            url: {
                linkedin: 'https://www.linkedin.com/in/welsouza22/',
                github: 'https://github.com/Weladm19',
                behance: ''
            },
            certificate: {
                code: "CBKJ-UHDC-VW44-LGPD",
                projectName: "Myclosety",
                projectYear: "2024-03-30",
                duration: "3",
                img: "https://www.startfuture.com.br/certificates/bootcamps/team-4/CBKJ-UHDC-VW44-LGPD.png",
                pdf: "https://www.startfuture.com.br/certificates/bootcamps/team-4/CBKJ-UHDC-VW44-LGPD.pdf",
            }
        },
    ]
}


export function getOldStudentByName(teamNumber: Number, fullName: String): OldStudent | undefined {
    let team: OldStudent[] = [];
    switch (teamNumber) {
        case 1:
            team = oldStudentsTeamOne();
            break;

        case 2:
            team = oldStudentsTeamTwo();
            break;

        default:
            return undefined;
    }

    return team.find((s) => s.fullName === fullName);
}

export function getOldStudentByCertificateCode(certificateCode: String): CertificateType | undefined {
    let team: OldStudent[] = oldStudentsTeamOne();
    let student = team.find((s) => s.certificate.code === certificateCode);
    if (student) {
        let certificateType: CertificateType = {
            info_certificate: {
                bootcamp_name: student.certificate.projectName,
                cod_certificate: student.certificate.code,
                date_certificate: student.certificate.projectYear,
                full_name: student.fullName,
                url: student.certificate.pdf,
                preview_img: student.certificate.img,
                duration: Number.parseInt(student.certificate.duration),
            },
            msg: ""
        }
        return certificateType
    }

    team = oldStudentsTeamTwo();
    student = team.find((s) => s.certificate.code === certificateCode);
    if (student) {
        let certificateType: CertificateType = {
            info_certificate: {
                bootcamp_name: student.certificate.projectName,
                cod_certificate: student.certificate.code,
                date_certificate: student.certificate.projectYear,
                full_name: student.fullName,
                url: student.certificate.pdf,
                preview_img: student.certificate.img,
                duration: Number.parseInt(student.certificate.duration),
            },
            msg: ""
        }
        return certificateType
    }

    team = oldStudentsTeamThree();
    student = team.find((s) => s.certificate.code === certificateCode);
    if (student) {
        let certificateType: CertificateType = {
            info_certificate: {
                bootcamp_name: student.certificate.projectName,
                cod_certificate: student.certificate.code,
                date_certificate: student.certificate.projectYear,
                full_name: student.fullName,
                url: student.certificate.pdf,
                preview_img: student.certificate.img,
                duration: Number.parseInt(student.certificate.duration),
            },
            msg: ""
        }
        return certificateType
    }

    team = oldStudentsTeamFour();
    student = team.find((s) => s.certificate.code === certificateCode);
    if (student) {
        let certificateType: CertificateType = {
            info_certificate: {
                bootcamp_name: student.certificate.projectName,
                cod_certificate: student.certificate.code,
                date_certificate: student.certificate.projectYear,
                full_name: student.fullName,
                url: student.certificate.pdf,
                preview_img: student.certificate.img,
                duration: Number.parseInt(student.certificate.duration),
            },
            msg: ""
        }
        return certificateType
    }
}