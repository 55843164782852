import style from '../../assets/css/CaseDetails.module.css'
import iconLinkedin from '../../../../assets/images/icon-linkedin.svg'
import iconBehance from '../../../../assets/images/icon-behance.svg'
import iconGithub from '../../../../assets/images/icon-github.svg'
import { OldStudent } from '../../../../types/OldStudent';
import useWindowSize from '../../../../utils/useWindowSize';
import { useState } from 'react';
import { oldStudentsTeamTwo } from '../../../../services/OldStudentService';

const CaseDetailsAboutTeamTwo = () => {
    const [oldStudents, setOldStudents] = useState<OldStudent[]>(oldStudentsTeamTwo());

    const { width } = useWindowSize();

    return (
        <div>
            <div className={style.container_content}>
                <h4 id='sector_7' className={style.case_subtitle}>
                    7.0 - Sobre a equipe
                </h4>
            </div>

            <div className={style.container_without_margin}>
                {oldStudents.map((oldStudent) => (
                    <div style={{margin:"50px 0px 150px 0px"}}>
                        <div className={style.case_user_certificate}>
                            <img src={oldStudent.photos[1]} alt='Foto certificado aluno' />
                        </div>
                        <div className={style.case_user_card_2}>
                            <div className={style.case_user_card} style={{
                                    margin: width && width > 930 ? "0px 30px" : "20px auto", 
                                    width: width && width > 930 ? "30%" : "80%"
                                }}>
                                <img src={oldStudent.photos[0]} alt="Foto aluno" className={style.case_card_img} />

                                <div className={style.container_flex}>
                                    <div className={style.case_card_text_container}>
                                        <span className={style.case_card_text_title}>
                                            {oldStudent.fullName}
                                        </span> <br />

                                        <span className={style.case_card_text}>
                                            {oldStudent.role}
                                        </span>
                                    </div>

                                    <div className={style.container_flex_card_icons}>
                                        <a href={oldStudent.url.linkedin} target="_blank"
                                            style={{ display: oldStudent.url.linkedin != "" ? "block" : "none" }}>
                                            <img src={iconLinkedin} alt="icon from linkedin" />
                                        </a>

                                        <a href={oldStudent.url.behance} target="_blank"
                                            style={{ display: oldStudent.url.behance != "" ? "block" : "none" }}>
                                            <img src={iconBehance} alt="icon from behance" />
                                        </a>

                                        <a href={oldStudent.url.github} target="_blank"
                                            style={{ display: oldStudent.url.github != "" ? "block" : "none" }}>
                                            <img src={iconGithub} alt="icon from github" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CaseDetailsAboutTeamTwo;