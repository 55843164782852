import { getImageCaseTwo } from '../../../../../services/ImageService';
import { OldStudent } from '../../../../../types/OldStudent';
import style from '../../../assets/css/CaseDetails.module.css'

const CaseDetailsContentDesignTwo: React.FC = () => {
    const image7: string = 'case-details-7.svg';
    const image8: string = 'case-details-8.svg';
    const image9: string = 'case-details-9.svg';

    const staffDesigner: OldStudent = {
        id: 0,
        fullName: "Marcelo Henrique",
        photos: [],
        role: "",
        url: {
            linkedin: "https://www.linkedin.com/in/marcelohenriquef/",
            github: "",
            behance: ""
        },
        certificate: {
            code: "",
            projectName: "",
            projectYear: "",
            duration: "",
            img: "",
            pdf: "",
        }
    };

    const marceloHenriqueLink = <a className={style.link_in_text} href={staffDesigner.url.linkedin} target="_blank">{staffDesigner.fullName}</a>;

    return (
        <>
            <h4 id='sector_2' className={style.case_subtitle}>
                2.0 - UX / UI
            </h4>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    Nesta turma não tivemos um aluno focado no design de telas UX/UI. Em casos em que um Squad não pode ser completo devido a falta de algum membro, utilizamos nossos STAFFs para ensinar sobre a área para os alunos e desenvolver esta parte do projeto com os alunos.
                </span> <br />
                <span className={style.case_text}>
                    Na segunda e terceira semana, nosso STAFF {marceloHenriqueLink} desenvolveu com todos os alunos telas Wireframes para compreensão de onde e como ficariam dispostas as telas do projeto.
                </span> <br />
                <span className={style.case_text}>
                    Posteriormente se seguiu a criação da identidade visual e detalhamento das telas para o desenvolvimento do projeto.
                </span>
            </div>

            <h5 className={style.case_text_bold}>
                Wireframe:
            </h5>

            <img src={getImageCaseTwo(image7)} alt="Imagem 7" className={style.case_img} />

            <div className={style.container_flex}>
                <div className={style.container_image}>
                    <img src={getImageCaseTwo(image8)} alt="Imagem 8" className={style.case_img} />
                </div>

                <div className={style.container_image}>
                    <img src={getImageCaseTwo(image9)} alt="Imagem 9" className={style.case_img} />
                </div>
            </div>

            <h5 className={style.case_text_bold}>
                Tela final:
            </h5>
        </>
    );
};

export default CaseDetailsContentDesignTwo;
