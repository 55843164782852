import style from './css/Button.module.css'

interface IFabButtonProps {
    onSubmit: (e: React.MouseEvent) => void
    icon: string
    background: string
}

const FabButton: React.FC<IFabButtonProps> = ({ onSubmit, icon, background }) => {
    return (
        <a href="#" className={style.fab_button} onClick={onSubmit} style={{background: background}}>
            <img src={icon} alt="Button icon"/>
        </a>
    );
}

export default FabButton;