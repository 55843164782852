import { getImageCaseTwo } from '../../../../../services/ImageService';
import style from '../../../assets/css/CaseDetails.module.css'

const CaseDetailsContentBrainstormTwo: React.FC = () => {
  const image4: string = 'case-details-4.svg';
  const image5: string = 'case-details-5.svg';
  const image6: string = 'case-details-6.svg';

  return (
    <>
      <h4 id='sector_1' className={style.case_subtitle}>
        1.0 - Brainstorm
      </h4>

      <div className={style.case_text_container}>
        <span className={style.case_text}>
          Na primeira semana criamos com os alunos o Brainstorm e o Briefing do projeto, utilizando a ideia base do projeto. Desenvolvemos ideias e funcionalidades para o projeto final, utilizando a técnica de “Card-Sorting” os alunos separaram suas ideias por categorias e assim criando a forma da solução final.
        </span>
      </div>

      <img src={getImageCaseTwo(image4)} alt="Imagem 4" className={style.case_img} />
      <img src={getImageCaseTwo(image5)} alt="Imagem 5" className={style.case_img} />

      <div className={style.case_text_container}>
        <span className={style.case_text}>
          No final, filtramos as principais e que seriam possíveis entregar em nossos 3 meses de projeto, apresentando o escopo e o fluxograma que seriam a base para os próximos passos de desenvolvimento.
        </span>
      </div>

      <img src={getImageCaseTwo(image6)} alt="Imagem 6" className={style.case_img} />
    </>
  );
};

export default CaseDetailsContentBrainstormTwo;
