import React from "react";
import styles from './css/Questions.module.css';
import Polygon from '../../../assets/images/icons/polygon.svg';

const Questions: React.FC = () => {
  const [opened1, setOpened1] = React.useState(false);
  const [opened2, setOpened2] = React.useState(false);
  const [opened3, setOpened3] = React.useState(false);
  const [opened4, setOpened4] = React.useState(false);
  const [opened5, setOpened5] = React.useState(false);
  const [opened6, setOpened6] = React.useState(false);
  const [opened7, setOpened7] = React.useState(false);
  const [opened8, setOpened8] = React.useState(false);
  const [opened9, setOpened9] = React.useState(false);

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Dúvidas? Encontre Suas Respostas Aqui!</h3>

      <div className={styles.questions_container}>
        <div className={styles.question_container}>
          <div className={`${opened1 ? styles.opened : ''} ${styles.question}`} onClick={() => { setOpened1(!opened1) }}>
            <p>
              Quem deve se inscrever no bootcamp da Start Future?
            </p>

            <div className={styles.icon}>
              <img src={Polygon} alt="arrow-down" />
            </div>
          </div>
          {opened1 && (
            <div className={styles.answer}>
              <p>
                Atendemos entusiastas da tecnologia interessados em áreas como UX/UI, desenvolvimento back-end, desenvolvimento front-end e gestão de projetos, com foco na prática de gestão ágil.
              </p>
            </div>
          )}
        </div>

        <div className={styles.question_container}>
          <div className={`${opened2 ? styles.opened : ''} ${styles.question}`} onClick={() => { setOpened2(!opened2) }}>
            <p>
              Quais são os pré-requisitos para participar do bootcamp?
            </p>

            <div className={styles.icon}>
              <img src={Polygon} alt="arrow-down" />
            </div>
          </div>
          {opened2 && (
            <div className={styles.answer}>
              <p>
                Para ser uma boa experiencia para você e para sua equipe, é necessário ter um conhecimento prévio para focar no desenvolvimento real de um projeto com a imersão de uma equipe completa.
              </p>
              <p>O que recomendamos por área:</p>
              <p><span>UX/UI</span>Possuir um mínimo conhecimento sobre criação de interfaces e planejamento de experiência. Já conhecer a ferramenta FIGMA é um excelente passo.</p>
              <p><span>Front-end</span>Possuir conhecimento de algoritmos e básico de HTML, CSS e JavaScript. Conhecimento em versionamento por Git é um ótimo começo.</p>
              <p><span>Back-end</span>Possuir conhecimento de lógica de programação, algoritmos, conhecimento em linguagens de programação (Python, Java, Go).</p>
              <p><span>Gestão de projetos</span>Possuir um mínimo de conhecimento sobre gestão ágil, por exemplo a estrutura Scrum.</p>
            </div>
          )}
        </div>

        <div className={styles.question_container}>
          <div className={`${opened3 ? styles.opened : ''} ${styles.question}`} onClick={() => { setOpened3(!opened3) }}>
            <p>
              Como o bootcamp da Start Future se diferencia de outros programas similares no mercado?
            </p>

            <div className={styles.icon}>
              <img src={Polygon} alt="arrow-down" />
            </div>
          </div>
          {opened3 && (
            <div className={styles.answer}>
              <p>
                O bootcamp da Start Future tem como diferencial provocar a experiência do aluno para ser 100% real como se estivesse dentro de uma empresa desenvolvendo o seu projeto. O aluno tem a oportunidade de trabalhar em uma SQUAD completa com designers, desenvolvedores e gestores. Todos com o mesmo objetivo, entregar o projeto final. Também durante o desenvolvimento do projeto o aluno terá diversas aulas e mentorias, seja em grupo ou individuais com nossos professores atuantes do mercado de tencnologia. Nos preocupamos em proporcionar a imersão real do aluno ao mercado de trabalho, <b>começamos enviando um lindo welcome kit em sua casa :)</b>
              </p>
            </div>
          )}
        </div>

        <div className={styles.question_container}>
          <div className={`${opened4 ? styles.opened : ''} ${styles.question}`} onClick={() => { setOpened4(!opened4) }}>
            <p>
              Qual é a duração do bootcamp?
            </p>

            <div className={styles.icon}>
              <img src={Polygon} alt="arrow-down" />
            </div>
          </div>
          {opened4 && (
            <div className={styles.answer}>
              <p>
                O bootcamp tem 3 meses de duração. Que serão separados entre aulas, mentorias e desenvolvimento prático do projeto final.
              </p>
            </div>
          )}
        </div>

        <div className={styles.question_container}>
          <div className={`${opened5 ? styles.opened : ''} ${styles.question}`} onClick={() => { setOpened5(!opened5) }}>
            <p>
              Quais habilidades, ferramentas e tecnologias serão abordadas durante o bootcamp?
            </p>

            <div className={styles.icon}>
              <img src={Polygon} alt="arrow-down" />
            </div>
          </div>
          {opened5 && (
            <div className={styles.answer}>
              <p>
                Todas as habilidades, ferramentas e tecnologias treinadas durante o bootcamp são as mesmas exigidas pelas empresas no mercado de acordo com as vagas ativas no momento. O aluno é tratado como um profissional completo então terá como treinamento a melhoria das suas “Soft skills”, ou seja, além das suas habilidades técnicas, serão tratadas suas habilidades como profissional, como:<br /> <b>Comunicação, liderança, auto-organização, apresentação.</b>
              </p>

              <div>
                <p>As tecnologias utilizadas são:</p>
                <ul>
                  <li>Front-end: HTML, CSS, JavaScript, React JS;</li>
                  <li>Back-end: Python, FastAPI, Docker, Database Relacional;</li>
                </ul>
              </div>

              <div>
                <p>As ferramentas utilizadas são:</p>
                <ul>
                  <li>Figma;</li>
                  <li>Notion;</li>
                  <li>VS Code;</li>
                  <li>GIT;</li>
                  <li>Taiga;</li>
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className={styles.question_container}>
          <div className={`${opened6 ? styles.opened : ''} ${styles.question}`} onClick={() => { setOpened6(!opened6) }}>
            <p>
              O bootcamp oferece algum tipo de suporte ou mentoria individual?
            </p>

            <div className={styles.icon}>
              <img src={Polygon} alt="arrow-down" />
            </div>
          </div>
          {opened6 && (
            <div className={styles.answer}>
              <p>
                Sim, o aluno tem acesso aos nossos mentores e convidados o tempo todo. Também o mesmo é incluído na nossa comunidade de ex-alunos e outros mentores em prática onde podem tirar dúvidas, pedir dicas, treinamentos etc.
              </p>
            </div>
          )}
        </div>

        <div className={styles.question_container}>
          <div className={`${opened7 ? styles.opened : ''} ${styles.question}`} onClick={() => { setOpened7(!opened7) }}>
            <p>
              Existem projetos práticos ou oportunidades de networking incluídas no programa?
            </p>

            <div className={styles.icon}>
              <img src={Polygon} alt="arrow-down" />
            </div>
          </div>
          {opened7 && (
            <div className={styles.answer}>
              <p>
                Sim, o aluno terá acesso ao seu projeto prático desenvolvido durante o bootcamp com o incentivo a apresentar nas entrevistas. Durante o bootcamp proporcionamos eventos e conexões com outros profissionais no mercado que servem como um excelente networking.
              </p>
            </div>
          )}
        </div>

        <div className={styles.question_container}>
          <div className={`${opened8 ? styles.opened : ''} ${styles.question}`} onClick={() => { setOpened8(!opened8) }}>
            <p>
              Como o bootcamp da Start Future prepara os alunos para o mercado de trabalho?
            </p>

            <div className={styles.icon}>
              <img src={Polygon} alt="arrow-down" />
            </div>
          </div>
          {opened8 && (
            <div className={styles.answer}>
              <p>
                O aluno é treinado para apresentar os seus projetos de forma clara e amigável para chamar atenção dos recrutadores, também provocamos a melhorar de suas habilidades pessoais que as empresas mais procuram, chamadas de “Softskills”, onde o profissional se mostra a melhor opção para aquela vaga. Além do conhecimento técnico, alinhado a cultura da empresa.
              </p>
            </div>
          )}
        </div>

        <div className={styles.question_container}>
          <div className={`${opened9 ? styles.opened : ''} ${styles.question}`} onClick={() => { setOpened9(!opened9) }}>
            <p>
              Qual o valor para ingressar no bootcamp?
            </p>

            <div className={styles.icon}>
              <img src={Polygon} alt="arrow-down" />
            </div>
          </div>
          {opened9 && (
            <div className={styles.answer}>
              <p>
                Acreditamos em democratizar a educação e deixá-la o mais acessível possível as pessoas. Por isso cobramos o valor de <strong>R$300,00</strong> para dar oportunidade de mais pessoas entrarem em nosso bootcamp.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Questions;
