import style from '../assets/css/CaseDetails.module.css'
import Footer from '../../../components/Footer';
import CaseDetailsIntroductionTwo from './caseDetailsTwo/CaseDetailsIntroductionTwo';
import CaseDetailsIndex from './CaseDetailsIndex';
import CaseDetailsAboutTeamTwo from './caseDetailsTwo/CaseDetailsAboutTeamTwo';
import CaseDetailsContentTwo from './caseDetailsTwo/CaseDetailsContentTwo';

const CaseDetailsTwo: React.FC = () => {

  return (
    <div className={style.case_detais_body}>
      <CaseDetailsIntroductionTwo />
      <CaseDetailsIndex />
      <CaseDetailsContentTwo />
      <CaseDetailsAboutTeamTwo />
      <Footer />
    </div>
  );
};

export default CaseDetailsTwo;
