import React from "react";
import { Link } from "react-router-dom";
import BigLogo from '../../../assets/images/big-logo.svg';
import styles from './css/CTASection.module.css';

const CTASection: React.FC = () => {
  return (
    <section className={styles.container}>
      <div className={styles.logo}>
        <img src={BigLogo} alt="Logo StartFuture" />
      </div>
      <div className={styles.text}>
        <h3>Se interessou pelo nosso bootcamp? Se inscreva e vamos entrar em contato em breve </h3>
        <Link to="/participant/forms">
          Quero participar
        </Link>
      </div>
    </section>
  );
}

export default CTASection;
