import CertificateComp from "./components/CertificateComp";
import NavBar from "../../components/NavBar";
import { useIsMobileOrTablet } from "../../utils/Style";

const Certificates = () => {
    return (
        <>
            <NavBar
                isDark={true}
                background={!!useIsMobileOrTablet() ? '' : 'rgba(0, 0, 0, 0.40)'}
                hideLogo={!!useIsMobileOrTablet()}
            />
            <CertificateComp />
        </>
    );
}

export default Certificates;