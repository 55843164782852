import { getImageCaseOne } from '../../../../../services/ImageService';
import { getOldStudentByName } from '../../../../../services/OldStudentService';
import style from '../../../assets/css/CaseDetails.module.css'

const CaseDetailsContentBrainstormOne = () => {
  const image4: string = 'case-details-4.svg';
  const image5: string = 'case-details-5.svg';
  const image6: string = 'case-details-6.svg';
  const image7: string = 'case-details-7.svg';
  const image8: string = 'case-details-8.svg';

  const gustavoFernandes = getOldStudentByName(1, 'Gustavo Fernandes');
  const gustavoFernandesLink = <a className={style.link_in_text} href={gustavoFernandes?.url.linkedin} target="_blank">{gustavoFernandes?.fullName}</a>;

  return (
    <>
      <h4 id='sector_1' className={style.case_subtitle}>
        1.0 - Brainstorm
      </h4>

      <div className={style.case_text_container}>
        <span className={style.case_text}>
          A Squad começou o levantamento de ideias pelo nome do produto, WORKSTATION, que foi o nome escolhido para representar esse case. Logo em seguida discutiram o que esse produto iria oferecer, quanto iria custar e seu funcionamento de ponta a ponta.
        </span>
      </div>

      <div className={style.case_text_container}>
        <span className={style.case_text}>
          Com o nome e as característas em mãos, o {gustavoFernandesLink} integrante da turma responsável pelo design, criou o Branding do Workstation, assim criando as primeiras formas do projeto.
        </span>
      </div>

      <div className={style.container_flex}>
        <div className={style.container_image}>
          <img src={getImageCaseOne(image4)} alt="Imagem 4" className={style.case_img} />
        </div>

        <div className={style.container_image}>
          <img src={getImageCaseOne(image5)} alt="Imagem 5" className={style.case_img_responsive_big} />

          <img src={getImageCaseOne(image6)} alt="Imagem 6" className={style.case_img_responsive_big} />

          <div className={style.container_flex_start}>
            <img src={getImageCaseOne(image7)} alt="Imagem 7" className={style.case_img_responsive_small} />

            <img src={getImageCaseOne(image8)} alt="Imagem 8" className={style.case_img_responsive_small} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseDetailsContentBrainstormOne;
