import { getImageCaseTwo } from '../../../../../services/ImageService';
import { getOldStudentByName } from '../../../../../services/OldStudentService';
import style from '../../../assets/css/CaseDetails.module.css';

const CaseDetailsContentManagerTwo = () => {
    const image16: string = 'case-details-16.svg';

    const giovanaCosta = getOldStudentByName(2, "Giovana de Almeida");
    const giovanaCostaLink = <a className={style.link_in_text} href={giovanaCosta?.url.linkedin} target="_blank">{giovanaCosta?.fullName}</a>;

    return (
        <>
            <h4 id='sector_5' className={style.case_subtitle}>
                5.0 - Gestão de projetos
            </h4>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    Um projeto não pode ser coordenado com maestria sem uma boa metodologia por trás, muito menos sem um líder o organizando e uma equipe o seguindo. Em nosso projeto tivemos como Scrum Master a {giovanaCostaLink} que orquestrou a metodologia ágil Scrum.
                    <br/> Tivemos quatro sprints, sendo nossa primeira sprint planning no dia 08/04, após o encerramento das atividades de Brainstorm e planejamento do projeto. A cada duas semanas faziamos Sprints reviews, retros e plannings para revisar o projeto e organizar o as tarefas das próximas semanas.
                    <br/> A cada planning botamos em prática técnicas de priorização pelo Moscow e estimativa de tarefas pelo Planning Poker para melhor decidir qual task seguir primeiro.
                    <br/> A última sprint se encerrou dia 03/06, duas semanas antes do final do projeto, período em que usamos para corrigir bugs e nos preparar para a apresentação final.
                    <br/> No dia a dia, além de nossa ajuda personalizada, também tirávamos 15 minutos para nos unir as dailies. Uma cerimônia de 15 minutos diários onde contamos o que fizemos, o que iriamos fazer e se havia impedimentos em nossas tarefas.
                    <br/> Toda nossa organização foi feita pela ferramenta Taiga.
                </span>
            </div>

            <img src={getImageCaseTwo(image16)} alt="Imagem 16" className={style.case_img} />
        </>
    );
};

export default CaseDetailsContentManagerTwo;
