const hostImg: String  = 'https://www.startfuture.com.br/images';

export const getStaticImage = (path: string):string => {
    return `${hostImg}/${path}`;
}

export const getImageCaseOne = (imageName: string):string => {
    return `${hostImg}/case_details_one/${imageName}`;
}

export const getImageCaseTwo = (imageName: string):string => {
    return `${hostImg}/case_details_two/${imageName}`;
}
