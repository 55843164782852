import { Link } from 'react-router-dom';
import LogoWhite from '../../assets/images/logo-name-icon-white.svg';
import LogoBlack from '../../assets/images/logo-name-icon-black.svg';
// import FlagBrazil from '../../assets/images/flag-brazil.svg';
// import FlagUsa from '../../assets/images/flag-usa.svg';
import style from './css/NavBar.module.css';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';

type NavBarProps = {
  isDark?: boolean;
  hideLogo?: boolean;
  background?: string;
  shadow?: string;
  hideNav?: boolean;
}

const NavBar: React.FC<NavBarProps> = ({
  isDark = false,
  hideLogo = false,
  background = 'none',
  shadow = 'none',
  hideNav = false
}) => {
  const [active, setActive] = useState(false);
  // const [language, setLanguage] = useState('pt');

  return (
    <header
      className={`${style.header} ${isDark ? style.is_dark : ''} ${hideNav ? style.hide_nav : ''}`}
      style={{
        backgroundColor: background,
        boxShadow: shadow,
      }}
    >
      <div className={style.container}>
        <Link to="/" className={`${style.logo} ${hideLogo ? style.hide_logo : ''}`}>
          {
            isDark ? (
              <img src={LogoWhite} alt="Logo" />
            ) : (
              <img src={LogoBlack} alt="Logo" />
            )
          }
        </Link>
        <nav className={`${style.nav} ${active && style.active}`}>
          <button
            title='Menu'
            aria-haspopup={true}
            aria-controls='menu'
            aria-expanded={active}
            aria-label={`${!active ? 'Abrir' : 'Fechar'} menu`}
            className={style.menu_button} onClick={() => {
              setActive((prev) => !prev)
              !active ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
            }
            }
          />
          <ul className={style.ul} id="menu" role="menu">
            <li className={style.li}>
              <NavLink
                className={({ isActive }) => `${style.navlink} ${isActive ? style.navlink_active : ''}`}
                to="/" end
                onClick={() => { setActive(false); document.body.style.overflow = 'auto' }}
              >
                Home
              </NavLink>
            </li>
            <li className={style.li}>
              <NavLink
                className={({ isActive }) => `${style.navlink} ${isActive ? style.navlink_active : ''}`}
                to="/cases"
                onClick={() => { setActive(false); document.body.style.overflow = 'auto' }}
              >
                Cases
              </NavLink>
            </li>
            {/* <li className={style.li}>
              <NavLink
                className={({ isActive }) => `${style.navlink} ${isActive ? style.navlink_active : ''}` }
                to="about"
                onClick={() => { setActive(false) }}
              >
                Sobre
              </NavLink>
            </li> */}
            <li className={style.li}>
              <NavLink
                className={({ isActive }) => `${style.navlink} ${isActive ? style.navlink_active : ''}`}
                to="/certificates"
                onClick={() => { setActive(false) }}
              >
                Certificados
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className={style.language}>
          {/* <button
            className={`${style.language_btn} ${language === 'pt' ? style.active_lang : ''}`}
            onClick={() => { setLanguage('pt') }}
          >
            <img src={FlagBrazil} alt="Brazil" />
          </button>
          <button
            className={`${style.language_btn} ${language === 'en' ? style.active_lang : ''}`}
            onClick={() => { setLanguage('en') }}
          >
            <img src={FlagUsa} alt="USA" />
          </button> */}
        </div>
      </div>
    </header>
  )
}

export default NavBar