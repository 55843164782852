import style from '../../assets/css/CaseDetails.module.css'
import CaseDetailsContentBrainstormTwo from './caseDetailsContent/CaseDetailsContentBrainstormTwo';
import CaseDetailsContentDesignTwo from './caseDetailsContent/CaseDetailsContentDesignTwo';
import CaseDetailsContentBackendTwo from './caseDetailsContent/CaseDetailsContentBackendTwo';
import CaseDetailsContentFrontendTwo from './caseDetailsContent/CaseDetailsContentFrontendTwo';
import CaseDetailsContentManagerTwo from './caseDetailsContent/CaseDetailsContentManagerTwo';
import CaseDetailsContentPresentationTwo from './caseDetailsContent/CaseDetailsContentPresentationTwo';
import Video from '../../../../components/Video';
import { getImageCaseTwo } from '../../../../services/ImageService';

const CaseDetailsContentTwo = () => {
    const image10: string = 'case-details-10.svg';
    const image11: string = 'case-details-11.svg';
    const image12: string = 'case-details-12.svg';
    const image17: string = 'case-details-17.svg';

    return (
        <>
            <div className={style.container_content}>
                <CaseDetailsContentBrainstormTwo />
                <CaseDetailsContentDesignTwo />
            </div>

            <div className={style.container_without_margin}>
                <img src={getImageCaseTwo(image10)} alt="Imagem 10" className={style.case_img} />
                <img src={getImageCaseTwo(image11)} alt="Imagem 11" className={style.case_img} />
                <img src={getImageCaseTwo(image12)} alt="Imagem 12" className={style.case_img} />
            </div>

            <div className={style.container_content}>
                <CaseDetailsContentBackendTwo />
                <CaseDetailsContentFrontendTwo />
                <CaseDetailsContentManagerTwo />
                <CaseDetailsContentPresentationTwo />
            </div>

            <div className={style.container_without_margin}>
                <img src={getImageCaseTwo(image17)} alt="Imagem 17" className={style.case_img}
                    style={{ marginTop: "100px", marginBottom: "100px" }} />

                <Video title='Vídeo Turma 2' link='https://www.youtube.com/embed/occD696tNQQ' />
            </div>
        </>
    );
}

export default CaseDetailsContentTwo