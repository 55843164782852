import Video from '../../../components/Video';
import style from './css/HomeVideo.module.css'

const HomeVideo = () => {
  return (
    <div style={{ paddingBottom: '7vh' }}>
      <div className={style.container}>
        <div className={style.video}>
          <Video title='Vídeo de apresentação' link='https://www.youtube.com/embed/occD696tNQQ' />
        </div>
      </div>
    </div>
  )
}

export default HomeVideo;
