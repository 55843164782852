import WhatsappIcon from '../../assets/images/icons/icon-whatsapp.svg'
import Footer from '../../components/Footer';
import Description from './components/Description';
import IDE from './components/IDE';
import InitialHome from './components/InitialHome';
import RoadmapTimeline from './components/RoadmapTimeline';
import TeamWorkWith from './components/TeamWorkWith';
import HomeVideo from './components/HomeVideo';
import NavBar from '../../components/NavBar';
import Questions from './components/Questions';
import CTASection from './components/CTASection';
import FabButton from '../../components/Buttons/FabButton';

const Home = () => {
    return (
        <div style={{ backgroundColor: '#f4f5fa' }}>
            <NavBar isDark />
            <InitialHome />
            <div>
                <TeamWorkWith />
                <IDE />
                <RoadmapTimeline />
                <Description />
                <Questions />
                <HomeVideo />
                <FabButton
                    onSubmit={(e) => window.open("https://api.whatsapp.com/send?phone=05511914888166&text=Olá, gostaria de saber mais sobre o bootcamp!", '_blank')}
                    icon={WhatsappIcon}
                    background='linear-gradient(102.53deg, #4BCB5A 25.83%, #24B53E 72.53%)' />
            </div>
            <CTASection />
            <Footer />
        </div>
    );
}

export default Home;