import style from './css/Video.module.css'

interface IVideoProps {
    title: string;
    link: string;
}

const Video: React.FC<IVideoProps> = ({ title, link }) => {
    return (
        <iframe title={title} className={style.iframe} src={link} frameBorder="0" allowFullScreen />
    );
}

export default Video;