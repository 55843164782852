import { getOldStudentByName } from '../../../../../services/OldStudentService';
import style from '../../../assets/css/CaseDetails.module.css'

const CaseDetailsContentFrontendTwo = () => {
    const henriqueJordao = getOldStudentByName(2, "Henrique Jordão");
    const thiagoYuske = getOldStudentByName(2, "Thiago Yuske");
    const alessandroJanicki = getOldStudentByName(2, "Alessandro Janicki");

    const henriqueLink = <a className={style.link_in_text} href={henriqueJordao?.url.linkedin} target="_blank">{henriqueJordao?.fullName}</a>;
    const thiagoLink = <a className={style.link_in_text} href={thiagoYuske?.url.linkedin} target="_blank">{thiagoYuske?.fullName}</a>;
    const alessandroLink = <a className={style.link_in_text} href={alessandroJanicki?.url.linkedin} target="_blank">{alessandroJanicki?.fullName}</a>;

    return (
        <>
            <h4 id='sector_4' className={style.case_subtitle}>
                4.0 - Front-end
            </h4>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    O time de front-end foi composto pelo {henriqueLink}, {thiagoLink} e {alessandroLink} (este full-stack). Eles foram responsáveis pela construção, navegação e consumo de APIs de todo projeto Noví, disponibilizando visualização Web e Mobile.
                </span>
            </div>

            <h5 className={style.case_text_bold}>
                O que era esperado:
            </h5>

            <div className={style.case_text_container}>
                <ul className={style.case_text}>
                    <li>Criação de telas com o layout definido pelo designer;</li>
                    <li>Navegação e usabilidade dos componentes da tela;</li>
                    <li>Componentização dos elementos;</li>
                    <li>Responsividade (visualização web e mobile);</li>
                    <li>Consumo de APIs.</li>
                </ul>
            </div>

            <h5 className={style.case_text_bold}>
                O que foi feito:
            </h5>

            <div className={style.case_text_container}>
                <ul className={style.case_text}>
                    <li>Iniciamos pela construção da Landing Page (tela de apresentação da Noví) com comentários em carrosséis e navegação para próximas telas;</li>
                    <li>Seguindo para o fluxo de login, criamos as telas de cadastro de usuários, login e esqueci a senha, onde consumimos as APIs para criação de token, registro de usuário e resetar senha;</li>
                    <li>Após logado, todas as telas foram validadas com tag Privacy, ou seja, acessáveis apenas com um token validado pelo back-end. Seguimos com a construção da tela Home para gerenciamento das viagens e do perfil do usuário;</li>
                    <li>E finalizamos com a criação do fluxo de nova reserva, onde consumimos a API de cálculo de probabilidade mostrando ao usuário a informação da viagem escolhida, e seguimos para a tela de pagamentos<strong>*</strong> e registro de nova viagem.</li>
                </ul>

                <br />
                <span className={style.case_text}>
                    <strong>*</strong>O pagamento foi feito apenas pelo front-end, simulando um pagamento real.
                </span>
            </div>

            <h5 className={style.case_text_bold}>
                Tecnologias & Conceitos utilizadas::
            </h5>

            <div className={style.case_text_container}>
                <ul className={style.case_text}>
                    <li>HTML5;</li>
                    <li>React JS;</li>
                    <li>CSS com Styled Components;</li>
                    <li>Consumo de APIs via Axios;</li>
                    <li>Git e Github;</li>
                    <li>Deploy de recursos pelo AWS.</li>
                </ul>
            </div>
        </>
    );
};

export default CaseDetailsContentFrontendTwo;
