import { getImageCaseTwo } from '../../../../../services/ImageService';
import { getOldStudentByName } from '../../../../../services/OldStudentService';
import style from '../../../assets/css/CaseDetails.module.css'

const CaseDetailsContentBackendTwo = () => {
    const image13: string = 'case-details-13.svg';
    const image14: string = 'case-details-14.svg';
    const image15: string = 'case-details-15.svg';

    const giovanaCosta = getOldStudentByName(2, "Giovana de Almeida");
    const gabrielTorres = getOldStudentByName(2, "Gabriel Torres");
    const luizHenrique = getOldStudentByName(2, "Luiz Henrique");
    const alessandroJanicki = getOldStudentByName(2, "Alessandro Janicki");

    const giovanaCostaLink = <a className={style.link_in_text} href={giovanaCosta?.url.linkedin} target="_blank">{giovanaCosta?.fullName}</a>;
    const gabrielTorresLink = <a className={style.link_in_text} href={gabrielTorres?.url.linkedin} target="_blank">{gabrielTorres?.fullName}</a>;
    const luizHenriqueLink = <a className={style.link_in_text} href={luizHenrique?.url.linkedin} target="_blank">{luizHenrique?.fullName}</a>;
    const alessandroLink = <a className={style.link_in_text} href={alessandroJanicki?.url.linkedin} target="_blank">{alessandroJanicki?.fullName}</a>;

    return (
        <>
            <h4 id='sector_3' className={style.case_subtitle}>
                3.0 - Back-end
            </h4>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    O time de back-end foi representado pela {giovanaCostaLink}, {gabrielTorresLink}, {luizHenriqueLink}, e nosso aluno full-stack {alessandroLink}. Eles foram responsáveis por toda a aplicação back-end da Novi, criando a experiência de uma agencia de turismo por IA (Inteligência Artificial).
                </span>
            </div>

            <h5 className={style.case_text_bold}>
                O que foi disponibilizado:
            </h5>

            <div className={style.case_text_container}>
                <ul className={style.case_text}>
                    <li>Fluxograma da Jornada do Usuário;</li>
                    <li>Diagramas de caso de uso;</li>
                    <li>Diagramas de caso de teste.</li>
                </ul>
            </div>

            <h5 className={style.case_text_bold}>
                O que era esperado:
            </h5>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    Aplicação Restful com endpoints protegidos por JWT e conexão com banco de dados, usando o conceito e a utilização de boas praticas de desenvolvimento. De início foi criado o repositório back-end e a estrutura da aplicação FastAPI, juntamente com o banco de dados seguindo o seguinte diagrama:
                </span>
            </div>

            <img src={getImageCaseTwo(image13)} alt="Imagem 13" className={style.case_img} />

            <h5 className={style.case_text_bold}>
                O que foi feito:
            </h5>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    Foi criado o CRUD de cada tabela e seus respectivos relacionamentos, comunicando com o banco de dados MySQL local utilizando o Docker. Foi desenvolvido um sistema de validação por token JWT para o sistema de login. E o Core do projeto, o cálculo de probabilidade.
                </span>
                <span className={style.case_text}> <br />
                    O cálculo de probabilidade se inicia em nosso questionário. São respondidas 9 perguntas que armazenamos em 4 tabelas com valores numéricos em que cada número representa uma opção do questionário. Alimentamos nosso banco de dados com informações de locais, hotéis, meios de transportes e passeios com os mesmos parâmetros perguntas e baseados em valores numéricos que representam estes lugares.
                </span>
            </div>

            <img src={getImageCaseTwo(image14)} alt="Imagem 14" className={style.case_img} />

            <div className={style.case_text_container}>
                <span className={style.case_text}> <br />
                    O cálculo de probabilidade se baseia na comparação dos valores dos parâmetros entre o questionário e os diversos locais. Subtraindo os dois valores com um retorno módulo (sempre positivo), podendo assim nos dizer o melhor local para este usuário a partir do menor valor entre questionário e local encontrado.
                </span>
            </div>

            <img src={getImageCaseTwo(image15)} alt="Imagem 15" className={style.case_img} />

            <h5 className={style.case_text_bold}>
                Tecnologias & Conceitos utilizadas:
            </h5>

            <div className={style.case_text_container}>
                <ul className={style.case_text}>
                    <li>FastAPI;</li>
                    <li>MySQL;</li>
                    <li>JWT;</li>
                    <li>Docker;</li>
                    <li>Git e GitHub;</li>
                    <li>Comunicação Restfull (JSON);</li>
                    <li>Algoritmo de recomendação;</li>
                    <li>Deploy de recursos pelo AWS;</li>
                </ul>
            </div>
        </>
    );
};

export default CaseDetailsContentBackendTwo;
