import React, { useRef, useState } from 'react';
import style from './css/FirstSession.module.css';
import FirstSessionForms from './FirstSessionComponents/FirstSessionForms';
import { Student, studentCarrer } from '../../../types/Student';
import { getInitials } from '../../../utils/StringUtils';
import { studentCellphoneExists, studentEmailExists } from '../../../services/StudentService';
import SpinnerButton from '../../../components/Buttons/SpinnerButton';

interface IFirstSessionProps {
    student: Student
    nextSession: () => void
}

type LabelBox = {
    initials: string;
    fullName: string;
    careerValue: string;
}

const FirstSession: React.FC<IFirstSessionProps> = ({ student, nextSession }) => {
    const studentFirstSession = useRef<Student>(student);
    const [showLoader, setShowLoader] = useState(false);
    const [labelBox, setLabelBox] = useState<LabelBox>({
        initials: '',
        fullName: 'Nome aluno',
        careerValue: 'Cargo'
    });

    const handleChangeCareer = (careerKey: string): void => {
        setLabelBox({
            ...labelBox,
            careerValue: studentCarrer[careerKey],
        });

        studentFirstSession.current.idCareer = Number(careerKey);
    }

    const handleChangeName = (fullName: string): void => {
        setLabelBox({
            ...labelBox,
            initials: getInitials(fullName),
            fullName: fullName,
        });

        studentFirstSession.current.fullName = fullName;
    }

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        studentFirstSession.current.email = event.target.value;
    }

    const handleChangePhone = (phone?: 'E164Number' | undefined) => {
        if (phone !== undefined) {
            studentFirstSession.current.cellphone = phone.toString().replace('+', '0');
        }
    }

    const validateForms = async (event: React.MouseEvent) => {
        event.preventDefault();
        setShowLoader(true);
        setTimeout(() => setShowLoader(false), 10000)

        if ((!studentFirstSession.current.fullName || studentFirstSession.current.fullName.trim() == '')
            || (!studentFirstSession.current.email || studentFirstSession.current.email.trim() == '')
            || (!studentFirstSession.current.cellphone || studentFirstSession.current.cellphone.trim() == '')
            || (!studentFirstSession.current.idCareer || studentFirstSession.current.idCareer == 0)) {
            alert('Por favor, preencha todos os campos');
            setShowLoader(false);
            return;
        }

        let studentExists = false;
        try {
            const respEmail = await studentEmailExists(studentFirstSession.current.email);
            const respCellphone = await studentCellphoneExists(studentFirstSession.current.cellphone);

            if (respEmail.status === 422) {
                alert('Email inválido!');
                setShowLoader(false);
                return;
            }
            if (respCellphone.status === 422) {
                alert('Telefone inválido!');
                setShowLoader(false);
                return;
            }
            if (respEmail.status === 409 || respCellphone.status === 409) {
                studentExists = true;
            }
        } catch (err) {
            studentExists = true;
        }

        setShowLoader(false);
        nextSession();
    }

    return (
        <div className={style.session_grid}>
            <div className={style.label_container}>
                <div className={style.label_box}>
                    <div className={style.box_photo}>
                        <label>{labelBox.initials}</label>
                    </div>

                    <div className={style.box_text}>
                        <h1>{labelBox.fullName}</h1>

                        <label>{labelBox.careerValue}</label>
                    </div>
                </div>

                <div className={style.label_tag}>
                    Designer
                </div>
            </div>
            <div className={style.forms_container}>
                <h1>
                    Nos informe mais sobre você :)
                </h1>

                <FirstSessionForms
                    student={studentFirstSession.current}
                    handleChangeName={handleChangeName}
                    handleChangeCareer={handleChangeCareer}
                    handleChangeEmail={handleChangeEmail}
                    handleChangePhone={handleChangePhone} />

                <SpinnerButton onSubmit={(e) => validateForms(e)} text='Prosseguir' loading={showLoader}/>
            </div>
        </div>
    );
}

export default FirstSession;