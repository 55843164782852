import { getImageCaseOne } from '../../../../../services/ImageService';
import style from '../../../assets/css/CaseDetails.module.css'

const CaseDetailsContentPresentationOne: React.FC = () => {
    const image20: string = 'case-details-20.svg';
    const image21: string = 'case-details-21.svg';
    const image22: string = 'case-details-22.svg';
    const image23: string = 'case-details-23.svg';

    return (
        <>
            <h4 id='sector_6' className={style.case_subtitle}>
                6.0 - Apresentação
            </h4>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    Nada melhor do que finalizar o seu primeiro projeto com a sua primeira apresentação. Os integrantes foram até um escritório apresentar o seu projeto para pessoas de diversas aréas da tecnologia. O objetivo da apresentação foi explicar para pessoas de fora o porquê do projeto, quais foram os problemas que iriam solucionar e principalmente o que cada um fez durante esses 3 meses. Além de mostrar o que foram capazes de aprender e desenvolver em um curto período, os mesmos criavam o seu primeiro “Networking”.
                </span>
            </div>

            <div className={style.case_text_container}>
                <span className={style.case_text_bold}>
                    Essa última etapa do bootcamp é o que chamamos de “Cereja do bolo”, pois em 3 meses foram ensinados diversos itens da tecnologia essenciais para o mercado de trabalho, mas durante esta etapa foi mostrado em aplicação como a comunicação é o fator mais importante da carreira de cada um, como saber apresentar o seu trabalho, suas opiniões e também, como receber e aprender com feedbacks construtivos.
                </span>
            </div>

            <img src={getImageCaseOne(image20)} alt="Imagem 20" className={style.case_img} />

            <div className={style.container_flex}>
                <div className={style.container_image}>
                    <img src={getImageCaseOne(image21)} alt="Imagem 21" className={style.case_img} />
                </div>

                <div className={style.container_image}>
                    <img src={getImageCaseOne(image22)} alt="Imagem 22" className={style.case_img} />
                </div>
            </div>

            <img src={getImageCaseOne(image23)} alt="Imagem 23" className={style.case_img} />
        </>
    );
};

export default CaseDetailsContentPresentationOne;
