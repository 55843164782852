import style from './css/Certificate_404.module.css';
import StartFutureIcon from '../../../assets/images/icons/start_future_404.svg';

const Certificate_404 = () => {
  return (
    <div className={style.container}>
      <img src={StartFutureIcon} alt="Start Future Icon" className={style.image} />
      <p className={style.description}>Certificado inválido, revise o número informado e tente novamente</p>
    </div >
  )
};

export default Certificate_404;
