import style from './css/Certificates.module.css';
import SearchIcon from '../../../assets/images/icons/search.svg'
import SearchIconWhite from '../../../assets/images/icons/search_white.svg'
import Certificate from './Certificate';
import Certificate404 from './Certificate404';
import { useMemo, useState } from 'react';
import { CertificateType } from '../../../types/Certificate';
import { getOldStudentByCertificateCode } from '../../../services/OldStudentService';

const CertificateComp = () => {
  const [search, setSearch] = useState('');
  const [error, setError] = useState(false);
  const [certificate, setCertificate] = useState<CertificateType>();

  const searchLength = useMemo(() => {
    const searchWithoutHifen = search.replace(/-/g, '');
    return searchWithoutHifen.length;
  }, [search]);

  const onSearch = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let searchWithHifen = '';
    if (search.length == 16) {
      searchWithHifen = search.includes('-') ? search : `${search.slice(0, 4)}-${search.slice(4, 8)}-${search.slice(8, 12)}-${search.slice(12, 16)}`;
    } else {
      searchWithHifen = search.includes('-') ? search : `${search.slice(0, 5)}-${search.slice(5, 10)}-${search.slice(10, 15)}-${search.slice(15, 20)}`;
    }
    let certificate = getOldStudentByCertificateCode(searchWithHifen.toUpperCase());
    if (!certificate) {
      setError(true);
    } else {
      setError(false);
    }
    setCertificate(certificate);
  };

  return (
    <div className={style.container}>
      <div className={style.hero_image_container}>
        <form className={style.input_container} onSubmit={onSearch}>
          <img src={SearchIcon} alt="Search Icon" className={style.search_icon} />
          <div className={style.input}>
            <input
              type="text"
              placeholder="Insira o número do certificado aqui (sem hífen)"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              maxLength={/^[a-zA-Z0-9]+$/.test(search) ? 20 : 23}
              pattern="^[a-zA-Z0-9-]+$"
            />
            <span className={style.count}>{searchLength}/20</span>
          </div>
          <button
            disabled={searchLength === 0}
            onClick={onSearch}
            className={style.desktop_button}
          >
            Pesquisar
          </button>
          <button
            disabled={searchLength === 0}
            onClick={onSearch}
            className={style.mobile_button}
          >
            <img src={SearchIconWhite} alt="Pesquisar" height={23} />
          </button>
        </form>
      </div>
      {certificate && <Certificate certificateInfos={certificate.info_certificate} />}
      {error && <Certificate404 />}
    </div>
  )
}

export default CertificateComp;
