import { getImageCaseOne } from '../../../../../services/ImageService';
import { getOldStudentByName } from '../../../../../services/OldStudentService';
import style from '../../../assets/css/CaseDetails.module.css'

const CaseDetailsContentManagerOne = () => {
    const image17: string = 'case-details-17.svg';
    const image18: string = 'case-details-18.svg';
    const image19: string = 'case-details-19.svg';

    const selenaSaraiva = getOldStudentByName(1, 'Selena Saraiva');
    const selenaSaraivaLink = <a className={style.link_in_text} href={selenaSaraiva?.url.linkedin} target="_blank">{selenaSaraiva?.fullName}</a>;

    return (
        <>
            <h4 id='sector_5' className={style.case_subtitle}>
                5.0 - Gestão de projetos
            </h4>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    Para facilitar a organização do projeto, decidimos utilizar a metodologia ágil chamada SCRUM. Com a liderança de projetos feita pela {selenaSaraivaLink} a equipe se reunia uma vez por dia para realizar sua “Daily” com o objetivo de compartilhar o que estavam fazendo e seus impedimentos, dessa forma a nossa gestora de projetos conseguia organizar e acompanhar cada demanda feita. Essas demandas foram planejadas e criadas 1 vez a cada 2 semanas em uma cerimonia chamada de “Planning” em que, com base no planejamento e especificações do projeto, a gestora criava as “Tasks” para cada integrante trabalhar nas próximas 2 semanas, chamamos essa organização de “Sprint de 2 semanas”. A cada encerramento de “Sprint” tivemos entregas e a cada início de “Sprint” novas tarefas.
                </span>
            </div>

            <img src={getImageCaseOne(image17)} alt="Imagem 17" className={style.case_img} />

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    A organização de todas as tarefas eram feitas através do “Kanban”, assim a gestora tinha visibilidade e controle de tudo que estavam fazendo nas Sprints atuais, tudo o que entregaram nas passadas e também tudo que precisava ser feito nas futuras.
                </span>
            </div>

            <div className={style.case_text_container}>
                <span className={style.case_text}>
                    As “Task” eram criadas e documentadas da seguinte forma:
                </span>

                <ul className={style.case_text}>
                    <li>Status (Qual momento essa tarefa está);</li>
                    <li>Release (Qual pacote de entrega essa tarefa pertence);</li>
                    <li>Sprint (Qual sprint ela foi desenvolvida);</li>
                    <li>Journey (Qual fluxo essa tarefa se trata);</li>
                    <li>Squad/Participante (Quem está responsável por essa tarefa).</li>
                </ul>
            </div>

            <img src={getImageCaseOne(image18)} alt="Imagem 18" className={style.case_img} />

            <img src={getImageCaseOne(image19)} alt="Imagem 19" className={style.case_img} />
        </>
    );
};

export default CaseDetailsContentManagerOne;
