import style from '../../assets/css/CaseDetails.module.css'
import CaseDetailsContentBrainstormOne from './caseDetailsContent/CaseDetailsContentBrainstormOne'
import CaseDetailsContentDesignOne from './caseDetailsContent/CaseDetailsContentDesignOne'
import CaseDetailsContentBackendOne from './caseDetailsContent/CaseDetailsContentBackendOne'
import CaseDetailsContentFrontendOne from './caseDetailsContent/CaseDetailsContentFrontendOne'
import CaseDetailsContentManagerOne from './caseDetailsContent/CaseDetailsContentManagerOne'
import CaseDetailsContentPresentationOne from './caseDetailsContent/CaseDetailsContentPresentationOne'

const CaseDetailsContentOne = () => {
    return (
        <div className={style.container_content}>
            <CaseDetailsContentBrainstormOne />
            <CaseDetailsContentDesignOne />
            <CaseDetailsContentBackendOne />
            <CaseDetailsContentFrontendOne />
            <CaseDetailsContentManagerOne />
            <CaseDetailsContentPresentationOne />
        </div>
    );
}

export default CaseDetailsContentOne